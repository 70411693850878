var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-margin" },
    [
      _c("TitleComponent", {
        attrs: { placeholder: _vm.$t("fields.title"), item: _vm.item },
        on: { "title-TITLE_UPDATED": _vm.updateItem },
      }),
      _c("Footer", { attrs: { item: _vm.item } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }